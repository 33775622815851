@import "../mixins/media";

/*
ユーザーデータ

追加したページ・ブロック関する Project コンポーネントを定義します。

*/
a {
	color: #5cb1b1;
}
.ec-input input,
.ec-halfInput input,
.ec-numberInput input,
.ec-zipInput input,
.ec-telInput input,
.ec-select input,
.ec-birth input,
.ec-input textarea,
.ec-input select,
.ec-halfInput select,
.ec-numberInput select,
.ec-zipInput select,
.ec-telInput select,
.ec-select select,
.ec-birth select{
	font-size: 16px !important;
}
.nav_text {
	&-ja {
		display: block;
	}
	&-en {
		font-size: 65%;
	}
}
.nav_text-global {
	text-align: center;
}
.ec-headerNav__itemText{
	font-size: 10px;
	display: block;
}
.nav_footer {
	width: 100%;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 15;
	background: #ffffff;
	border-top: 1px solid #cccccc;
	&_link {
		flex-grow: 1;
		width: auto;
		padding: 10px 5px;
	}
    @include media_desktop() {
		display: none;
	}
	&_icon {
		font-size: 16px;
		line-height: 1;
	}
	&_text {
		margin-top: 5px;
		font-size: 10px;
		line-height: 1;
	}
}
.img__title{
	width: 100%;
	padding: 5px 10px 10px;
	text-align: center;
	display: block;
	line-height: 1;
	background-image:
		url("/html/user_data/assets/img/common/title_left.jpg"),
		url("/html/user_data/assets/img/common/title_right.jpg"),
		url("/html/user_data/assets/img/common/title_center.png");
	background-repeat:
		no-repeat,
		no-repeat,
		repeat-x;
	background-position:
		left,
		right,
		center;
	background-size:
		auto 100%,
		auto 100%,
		auto 100%;
	@include media_desktop {
		padding: 20px 40px;
	}
	img {
		width: auto;
		height: 16px;
		@include media_desktop {
			height: 26px;
		}
	}
}
.img__title-g{
	width: 100%;
	padding: 5px 10px 10px;
	text-align: center;
	display: block;
	line-height: 1;
	background-image:
		url("/html/user_data/assets/img/common/title_left-g.jpg"),
		url("/html/user_data/assets/img/common/title_right-g.jpg"),
		url("/html/user_data/assets/img/common/title_center.png");
	background-repeat:
		no-repeat,
		no-repeat,
		repeat-x;
	background-position:
		left,
		right,
		center;
	background-size:
		auto 100%,
		auto 100%,
		auto 100%;
	@include media_desktop {
		padding: 20px 40px;
	}
	img {
		width: auto;
		height: 18px;
		@include media_desktop {
			height: 26px;
		}
	}
}
.message {
	margin-bottom: 20px;
	padding-bottom: 20px;
    @include media_desktop() {
		margin-top: 20px;
		margin-bottom: 40px;
		padding-top: 20px;
		padding-bottom: 40px;
	}
	&__inner{
		margin: 0 auto;
		padding-bottom: 120px;
		background: {
			image: url("/html/user_data/assets/img/top/top_message_01.png"), url("/html/user_data/assets/img/top/top_message_02.png");
			size: 70px auto, 70px auto;
			repeat: no-repeat, no-repeat;
			position: calc(50% - 70px) 100%, calc(50% + 70px) 100%;
		}
		@include media_desktop() {
			padding-bottom: 0;
			padding-left: 145px;
			padding-right: 145px;
			background: {
				size: 120px auto, 120px auto;
				position: 0 100%, 100% 100%;
			}
		}
	}
	&__title {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		@include media_desktop {
			max-width: 260px;
		}
	}
	&__text {
		margin-top: 20px;
		font-size: 14px;
		line-height: 1.75;
		max-height: 16em;
		overflow: hidden;
		@include media_desktop {
			margin-top: 40px;
		}
		p {
			margin-top: 2em;
			&:first-child{
				margin-top: 0;
			}
		}
		&.message__text_active{
			max-height: 100%;
		}
	}
	&__btn {
		text-align: center;
		margin-top: 20px;
		&_inner{
			white-space: nowrap;
			min-width: 260px;
			display: inline-block;
			color: #ffffff;
			background: #cccccc;
			padding: 9px 100px;
			border-radius: 20px;
			cursor: pointer;
		}
	}
}
.about {
	&_title{
		&::after{
			display: none;
		}
		&_img{
			height: 24px;
			@include media_desktop() {
				height: 36px;
			}
		}
	}
	&_profile {
		margin-left: -10px;
		margin-right: -10px;
		@include media_desktop() {
			display: flex;
			margin-left: -20px;
			margin-right: -20px;
		}
		&_fugure{
			margin: 0;
			width: 100%;
			padding-left: 10px;
			padding-right: 10px;
			display: flex;
			@include media_desktop() {
				width: 200px;
				padding-left: 20px;
				padding-right: 20px;
				display: block;
			}
			&_img{}
			&_caption{
				font-size: 12px;
				margin-top: 1em;
				&_content{
					margin: 0;
					display: flex;
				}
				&_title{
					color: #F28FBF;
					&::after{
						content: "：";
					}
				}
				&_text{
					font-weight: bold;
				}
			}
		}
		&_info{
			width: 100%;
			margin-top: 10px;
			padding-left: 10px;
			padding-right: 10px;
			@include media_desktop() {
				width: calc(100% - 200px);
				margin-top: 0;
				padding-left: 20px;
				padding-right: 20px;
			}
			&_sec{
				border: 1px solid #F28FBF;
				border-radius: 8px;
				overflow: hidden;
				&_title{
					font-size: 16px;
					color: #ffffff;
					background: #F28FBF;
					margin: 0;
					padding-top: 10px;
					padding-bottom: 10px;
					padding-left: 40px;
					padding-right: 40px;
				}
				&_content{
					font-size: 12px;
					line-height: 1.75;
					list-style: none;
					margin: 0;
					padding: 0;
				}
				&_list{
					display: block;
					padding-top: 10px;
					padding-bottom: 10px;
					padding-left: 10px;
					padding-right: 10px;
					position: relative;
					z-index: 1;
					@include media_desktop() {
						padding-top: 20px;
						padding-bottom: 20px;
						padding-left: 20px;
						padding-right: 20px;
						display: flex;
					}
					&::before{
						content: "";
						width: 100%;
						height: 1px;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 1;
						background:#CCCCCC;
						@include media_desktop() {
							width: calc(100% - 80px);
							left: 40px;
						}
					}
				}
				&_icon{
					@include media_desktop() {
						padding-left: 20px;
						padding-right: 20px;
					}
					&_text{
						color: #ffffff;
						background: #F28FBF;
						padding-top: 5px;
						padding-bottom: 5px;
						padding-left: 5px;
						padding-right: 5px;
						white-space: nowrap;
						min-width: 7em;
						text-align: center;
						line-height: 1;
						display: inline-block;
						@include media_desktop() {
							display: block;
						}
					}
				}
				&_text{
					margin-top: 0.5em;
					@include media_desktop() {
						margin-top: 0;
					}
					&_strong {
						font-size: 14px;
					}
				}
			}
		}
	}
	&_greeting {
		&_title{
			padding-top: 20px;
			padding-bottom: 20px;
			margin-top: 20px;
			margin-bottom: 20px;
			@include media_desktop() {
				text-align: center;
				margin-top: 40px;
				padding-top: 40px;
				padding-bottom: 40px;
			}
			&_img{
				width: auto;
				height: 40px;
			}
		}
		&_sec{
			font-size: 14px;
			line-height: 1.75;
			position: relative;
			z-index: 1;
			@include media_desktop() {
				border-radius: 8px;
				padding-top: 40px;
				padding-bottom: 40px;
				padding-left: 40px;
				padding-right: 40px;
			}
			&::before{
				@include media_desktop() {
					content: "";
					border-top: 20px solid transparent;
					border-bottom: 20px solid transparent;
					position: absolute;
					z-index: -1;
					top: -20px;
				}
			}
			&_content{
				margin-top: 20px;
				padding-top: 20px;
				padding-bottom: 15px;
				padding-left: 15px;
				padding-right: 15px;
				background: #ffffff;
				border-radius: 8px;
				@include media_desktop() {
					padding-top: 40px;
					padding-bottom: 40px;
					padding-left: 40px;
					padding-right: 40px;
				}
				&:first-child{
					margin-top: 0;
				}
				&_title{
					font-size: 16px;
					font-weight: bold;
					line-height: 1.5;
					margin: 0;
					text-align: center;
					padding-bottom: 0.5em;
					position: relative;
					z-index: 1;
					@include media_desktop() {
						font-size: 20px;
						padding-bottom: 1em;
					}
					&::after{
						content: "";
						width: 80px;
						height: 1px;
						position: absolute;
						bottom: 0;
						left: 50%;
						z-index: 1;
						-webkit-transform: translateX(-50%);
						-moz-transform: translateX(-50%);
						-ms-transform: translateX(-50%);
						transform: translateX(-50%);
					}
				}
				&_text{
					margin-top: 1em;
					@include media_desktop() {
						margin-top: 2em;
					}
				}
				&_inner{
					margin: 0;
					padding: 0;
					list-style: none;
				}
				&_list{
					margin-top: 2em;
					margin-left: -15px;
					margin-right: -15px;
					padding-top: 2em;
					border-top: 1px solid #cccccc;
					&:first-child{
						padding-top: 0;
						border-top: none;
					}
					@include media_desktop() {
						display: flex;
					}
					.about_greeting_sec_content_text{
						margin: 0;
						padding-left: 15px;
						padding-right: 15px;
					}
				}
				&_icon{
					padding-left: 15px;
					padding-right: 15px;
					margin-bottom: 1em;
					&_text{
						font-weight: bold;
						color: #ffffff;
						background: #F28FBF;
						padding-top: 5px;
						padding-bottom: 5px;
						padding-left: 10px;
						padding-right: 10px;
						white-space: nowrap;
						min-width: 7em;
						text-align: center;
						display: block;
						line-height: 1;
					}
				}
			}
		}
		&_otowa {
			.about {
				&_greeting{
					&_title{
						padding-left: 60px;
						background-image: url("/html/user_data/assets/img/about/greeting_title_img01.png");
						background-repeat: no-repeat;
						background-size: 60px 60px;
						background-position: 0 50%;
						@include media_desktop() {
							padding-left: 160px;
							padding-right: 160px;
							background-size: 120px 120px;
							background-position: 40px 50%;
						}
					}
					&_sec{
						@include media_desktop() {
							background: #d8eff6;
						}
						&::before{
							@include media_desktop() {
								border-right: 20px solid #d8eff6;
							}
						}
						&_content{
							border: 1px solid #64BEDD;
							@include media_desktop() {
								border: none;
							}
							&_title{
								color: #64BEDD;
								&::after{
									background:#64BEDD;
								}
							}
							&_icon{
								&_text{
									background: #64BEDD;
								}
							}
						}
					}
				}
			}
		}
		&_hiwa {
			.about {
				&_greeting{
					&_sec{
						@include media_desktop() {
							background: #fcf2e3;
						}
						&::before{
							@include media_desktop() {
								border-right: 20px solid #fcf2e3;
							}
						}
						&_content{
							border: 1px solid #F4CA8E;
							@include media_desktop() {
								border: none;
							}
							&_title{
								color: #F4CA8E;
								&::after{
									background:#F4CA8E;
								}
							}
							&_icon{
								&_text{
									background: #F4CA8E;
								}
							}
						}
					}
				}
			}
		}
	}
}
.ec-role img.about_title_img{
	width: auto;
}
.ec-role img.about_profile_fugure_img{
	width: 80px;
	height: 80px;
	margin-right: 20px;
	@include media_desktop() {
		width: 160px;
		height: 160px;
		margin-right: 0;
	}
}
.ec-role img.about_greeting_title_img{
	width: auto;
	height: 20px;
	@include media_desktop() {
		height: 40px;
	}
}
.faq{
	&_sec{
		border: 1px solid #ccc;
		margin-top: 20px;
		margin-bottom: 0;
		border-radius: 4px;
		overflow: hidden;
		&_title{
			font-size: 16px;
			font-weight: bold;
			color: #ffffff;
			background: #f28fbf;
			margin: 0;
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 20px;
			padding-right: 20px;
			@include media_desktop() {
				font-size: 20px;
			}
			&_img{
				height: 20px;
			}
		}
		&_box {
			margin: 0;
			border-top: 1px solid #cccccc;
			&:first-child {
				margin-top: 0;
			}
			&_title {
				font-size: 16px;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 2.5em;
				padding-right: 45px;
				background: {
					image: url("/html/user_data/assets/img/faq/q_icon.png");
					size: 1.5em 1.5em;
					repeat: no-repeat;
					position: 15px .8em;
				}
				position: relative;
				z-index: 1;
				cursor: pointer;
				&::before,
				&::after{
					content: "";
					position: absolute;
					top: 50%;
					right: 20px;
					z-index: 1;
					background: #CCCCCC;
				}
				&::before{
					width: 16px;
					height: 2px;
					-webkit-transform: translateY(-1px);
					-moz-transform: translateY(-1px);
					-ms-transform: translateY(-1px);
					transform: translateY(-1px);
				}
				&::after{
					width: 2px;
					height: 16px;
					-webkit-transform: translate3d(-7px, -50%, 0);
					-moz-transform: translate3d(-7px, -50%, 0);
					-ms-transform: translate3d(-7px, -50%, 0);
					transform: translate3d(-7px, -50%, 0);
				}
				@include media_desktop() {
					padding-top: 20px;
					padding-bottom: 20px;
					padding-left: 45px;
					padding-right: 20px;
					background: {
						size: 28px 28px;
						position: 15px 1em;
					}
				}
				&.active{
					&::after{
						display: none;
					}
				}
			}
			&_content {
				margin: 0;
				padding-top: 20px;
				padding-bottom: 20px;
				padding-left: 20px;
				padding-right: 20px;
				font-weight: normal;
				line-height: 1.75;
				border-top: 1px dotted #cccccc;
				display: none;
			}
		}
	}
	&_cta {
		background: #f8f8f8;
		margin-top: 20px;
		padding: 20px;
		@include media_desktop() {
			margin-top: 40px;
			padding: 40px;
			text-align: center;
		}
		&_btn {
			max-width: 260px;
			margin: 0 auto;
		}
	}
	&_list {
		margin-top: 20px;
		@include media_desktop() {
			margin-top: 40px;
			text-align: center;
		}
		&_btn {
			max-width: 260px;
			margin: 0 auto;
			.ec-blockBtn--action{
				background: none;
				border: 1px solid #5cb1b1;
				color: #5cb1b1;
				@include media_desktop() {
					transition: all 0.3s ease;
					&:hover{
						background: #5cb1b1;
						color: #ffffff;
					}
				}
			}
		}
	}
}
.ec-contactRole img.faq_sec_title_img{
	width: auto;
}
.ec-contactRole p.faq_sec_box_content_text{
	margin-top: 1.5em;
	margin-bottom: 0;
	&:first-child{
		margin-top: 0;
	}
}
.faq_sec_box_content_text_strong{
	display: block;
}
.privacy{
	dl{
		border-top: 1px dotted #ccc;
		margin-bottom: 15px;
		&:first-child{
			border-top: none;
			margin-top: 20px;
			@include media_desktop() {
				margin-top: 30px;
			}
		}
		dt{
			margin-bottom: 4px;
		}
		dd{}
		p{
			line-height: 1.6;
		}
		ul{
			margin-top: 8px;
			padding-left: 1.5em;
		}
		li{}
	}
}
.contact{
	&_complete{
		&_text{
			margin-top: 40px;
			margin-bottom: 40px;
			text-align: left;
			&_list{
				margin-top: 2em;
			}
		}
	}
}
