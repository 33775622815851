@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  margin-top: 30px;
  padding-bottom: 56px;
  background: #f28fbf;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f8c7df+0,f28fbf+100 */
  background: #f8c7df; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #f8c7df 0%,
    #f28fbf 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #f8c7df 0%,
    #f28fbf 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #f8c7df 0%,
    #f28fbf 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8c7df', endColorstr='#f28fbf',GradientType=0 ); /* IE6-9 */
  @include media_desktop() {
	margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    @include media_desktop {
		margin-top: 40px;
		padding-top: 40px;
		padding-bottom: 40px;
	  }
  }
  & &__inner {
    @include media_desktop {
      @include container;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
	padding: 0;
	color: white;
	list-style: none;
	margin-bottom: 0;
	padding-top: 10px;
	padding-bottom: 10px;
	@include media_desktop {
		display: flex;
		margin-left: -20px;
		margin-right: -20px;
		padding-top: 0;
		padding-bottom: 0;
	}
	&__list {
		width: 100%;
		padding-bottom: .5em;
		padding-left: 20px;
		padding-right: 20px;
		@include media_desktop {
			width: 25%;
			padding-top: 0;
			padding-bottom: 0;
		}
		&__inner {
			margin-top: .5em;
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;
			@include media_desktop {
				margin-top: 15px;
				display: block;
			}
			&:first-child{
				margin-top: 0;
			}
			&__title {
				font-weight: bold;
				position: relative;
				z-index: 1;
				padding-left: 1.25em;
				margin-bottom: 0.25em;
				width: 100%;
				display: none;
				@include media_desktop {
					display: block;
				}
				&::before {
					content: "";
					width: 0.75em;
					height: 0.75em;
					background: #ffffff;
					position: absolute;
					top: .35em;
					left: 0;
					z-index: 1;
					font-size: 16px;
					display: block;
				}
			}
			&__text {
				width: 50%;
				position: relative;
				z-index: 1;
				font-size: 90%;
				&::before {
					content: "・";
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					padding: .5em .5em .5em 0;
				}
				@include media_desktop {
					width: auto;
					padding-left: 1.25em;
					font-size: 14px;
					&::before {
						padding: 0;
					}
				}
				a {
					padding: .25em .5em .25em 1.25em;
					@include media_desktop {
						padding: 0;
					}
				}
			}
		}
	}
	& &__info {
		height: 100%;
		display: flex;
		align-items: flex-end;
		@include media_desktop {
			padding-left: 20px;
			padding-right: 20px;
			border-left: 1px solid #ffffff;
		}
		&_inner{}
	}
	& &__link {
		color: #ffffff;
		text-decoration: none;
		display: block;
		@include media_desktop {
			display: inline-block;
		}
		@include media_desktop {
			&:hover {
				a {
				  opacity: 0.8;
				  text-decoration: none;
				}
			  }
		}
	}
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  padding: 20px 0 20px;
  text-align: center;
  color: white;

  @include media_desktop {
    padding: 40px 0 40px;
  }

  &__logo {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();
    a {
      color: inherit;
    }
    &:hover {
      a {
        opacity: 0.8;
        text-decoration: none;
      }
	}
	&_img{
		max-width: 240px;
	}
  }
  &__copyright {
	line-height: 1;
  }
}
