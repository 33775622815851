@import "../mixins/media";
@import "../mixins/projects";
/*
商品掲載

トップページに商品掲載するスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.1
*/

/*
商品アイテム（商品紹介B）

３項目横並びの商品アイテムを表示します。
必要に応じて商品詳細や、キャッチコピーなどを添えることが出来ます。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayB

Styleguide 7.1.1
*/
.ec-displayB {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include media_desktop {
    flex-direction: row;
  }
  & &__cell {
    width: 100%;
    margin-bottom: 16px;
    @include reset_link();
    @include media_desktop {
      width: 31.4466%;
      margin-bottom: 0;
    }
    &:hover {
      text-decoration: none;
      img {
        opacity: 0.8;
      }
      a {
        text-decoration: none;
      }
    }
  }
  & &__img {
    margin-bottom: 15px;
  }

  & &__catch {
    margin-bottom: 15px;
    text-decoration: none;
    font-weight: bold;
    color: #9a947e;
  }
  & &__comment {
    margin-bottom: 14px;
    text-decoration: none;
    color: #333333;
    font-size: 14px;
  }
  & &__link {
    text-decoration: none;
    font-weight: bold;
    color: #9a947e;
  }
}

/*
商品アイテム（商品紹介C）

４項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayC
p hoge

Styleguide 7.1.2
*/

.ec-displayC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  & &__cell {
    width: 47%;
    @include reset_link();
    @include media_desktop() {
      width: 22.8775%;
    }
    &:hover {
      a {
        text-decoration: none;
      }
      img {
        opacity: 0.8;
      }
    }
  }
  & &__img {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  & &__catch {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #9a947e;
  }
  & &__title {
    display: block;
    width: 100%;
    color: #333333;
  }
  & &__price {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #333333;
  }
  & &__price--sp {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #f28fbf;
  }
}

/*
商品アイテム（商品紹介D）

６項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayD

Styleguide 7.1.3
*/

.ec-displayD {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  @include media_desktop() {
    box-sizing: border-box;
    flex-wrap: nowrap;
  }

  & &__cell {
    width: 30%;
    margin-bottom: 8px;
    @include reset_link();
    @include media_desktop() {
      width: 14.3083%;
      margin-bottom: 16px;
    }
    &:hover {
      text-decoration: none;
      img {
        opacity: 0.8;
      }
    }
  }
  & &__img {
    display: block;
    width: 100%;
  }
}
