@import "../mixins/media";

/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  padding: 20px;
  background: #f8f8f8;
  @include media_desktop {
	padding: 40px 40px 20px 40px;
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    @include media_desktop {
      flex-wrap: nowrap;
      margin-left: -10px;
	  margin-right: -10px;
    }
  }

  & &__image {
    display: block;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;

    @include media_desktop {
      order: 2;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  & &__intro {
    width: 100%;
    color: black;

    @include media_desktop {
      order: 1;
      padding-left: 10px;
	  padding-right: 10px;
	  text-align: left;
    }
  }
  & &__introEnTitle {
    margin-bottom: 0.8em;
    font-size: 16px;
    font-weight: normal;
  }
  & &__introTitle {
	margin-bottom: 0.8em;
	margin-left: auto;
	margin-right: auto;

    @include media_desktop {
	  max-width: 400px;
	  margin-bottom: 0.5em;
	  margin-left: 0;
	  margin-right: 0;
      font-size: 30px;
    }
  }
  & &__introDescriptiron {
    margin-bottom: 20px;
    font-size: 14px;
	line-height: 1.75;
  }
}
