@import "../mixins/media";
/*
フォームラベル

フォームのラベルに関する要素を定義します。

sg-wrapper:
<div class="ec-registerRole">
  <div class="ec-off1Grid">
    <div class="ec-off1Grid__cell">
      <div class="ec-borderedDefs">
        <sg-wrapper-content/>
      </div>
    </div>
  </div>
</div>

Styleguide 3.3
*/

/*
ラベル

フォーム要素で利用するラベル要素です。

ex [お問い合わせページ　ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.1
*/
.ec-label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 5px;
}

/*
必須ラベル

必須文字を表示するラベル要素です。

ex [お問い合わせページ　必須ラベル部分](http://demo3.ec-cube.net/contact)


Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
        span.ec-required 必須
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.2
*/

.ec-required {
  display: inline-block;
  margin-left: 0.8em;
  vertical-align: 2px;
  color: #ffffff;
  background: #f28fbf;
  font-size: 12px;
  font-weight: normal;
  padding-left: .5em;
  padding-right: .5em;
  @include media_desktop {
    margin-left: 1em;
  }
}
